<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#13736f"
        spinner="spinner"
      />
      <v-flex xs12>
        <v-layout wrap fill-height>
          <v-flex xs12 class="mainbg2" pt-3>
            <template class="mainbg2">
              <v-layout wrap pl-10 pr-10 justify-center>
                <v-flex xs11 lg5 style="border-radius: 8px" class="cus3">
                  <v-tabs
                    v-model="subtab"
                    :show-arrows="false"
                    color="#13736f"
                    background-color="transparent"
                    centered
                    dark
                  >
                    <v-tab to="#tabs-3"
                      ><span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 18px;
                          font-weight: bolder;
                        "
                      >
                        AUCTIONS</span
                      ></v-tab
                    >
                    <v-tab to="#tabs-4"
                      ><span
                        class="mainfont"
                        style="
                          color: black;
                          font-size: 18px;
                          font-weight: bolder;
                        "
                      >
                        ORDERS</span
                      ></v-tab
                    >
                  </v-tabs>
                </v-flex>
              </v-layout>
              <v-tabs-items v-model="subtab" id="custom-tab-items">
                <v-tab-item value="tabs-3">
                  <v-layout
                    class="mainbg2"
                    pt-2
                    pr-2
                    pb-2
                    wrap
                    justify-start
                    pt-3
                  >
                    <v-flex xs12 text-center>
                      <v-layout wrap justify-center>
                        <v-flex xs11 lg4>
                          <v-layout wrap justify-center fill-height>
                            <v-flex xs10 align-self-center>
                              <v-card elevation="0" style="border-radius: 8px">
                                <v-layout wrap fill-height justify-center>
                                  <v-flex text-left xs10 pt-4 align-self-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 17px;
                                        font-weight: bold;
                                      "
                                      >Total Auction Price</span
                                    >
                                  </v-flex>
                                  <v-flex xs12 pb-4>
                                    <v-layout wrap justify-end fill-height>
                                      <v-flex
                                        pl-7
                                        xs7
                                        align-self-center
                                        class="mainfont"
                                        style="
                                          color: black;
                                          font-size: 18px;
                                          font-weight: bold;
                                        "
                                        >Rs &nbsp;{{
                                          totalAuctionPrice
                                        }}</v-flex
                                      >
                                      <v-flex
                                        xs1
                                        pb-1
                                        pr-16
                                        text-center
                                        align-self-center
                                        ><v-icon size="125%" color="green"
                                          >mdi-arrow-up-thin-circle-outline</v-icon
                                        ></v-flex
                                      >
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>

                            <v-flex xs12>
                              <v-layout
                                wrap
                                justify-center
                                v-for="(item, i) in barlist"
                                :key="i"
                              >
                                <v-flex xs12 pa-2 text-center>
                                  <span
                                    class="mainfont"
                                    style="
                                      color: #13736f;
                                      font-size: 16px;
                                      font-weight: bold;
                                    "
                                    >{{ item.categoryName }}</span
                                  >
                                </v-flex>

                                <v-flex xs10 align-self-center>
                                  <v-card
                                    elevation="0"
                                    style="border-radius: 8px"
                                  >
                                    <v-layout wrap fill-height justify-center>
                                      <v-flex xs5 pt-4 align-self-center>
                                        <v-layout wrap>
                                          <v-flex xs12 text-left>
                                            <span
                                              class="mainfont"
                                              style="
                                                color: black;
                                                font-size: 15px;
                                              "
                                              >Maximum Price</span
                                            >
                                          </v-flex>

                                          <v-flex xs12 pb-4 text-center>
                                            <v-layout wrap justify-center>
                                              <v-flex
                                                text-left
                                                xs10
                                                class="mainfont"
                                                style="
                                                  color: black;
                                                  font-size: 14px;
                                                  font-weight: bold;
                                                "
                                                >Rs &nbsp;{{
                                                  item.maxPrice
                                                }}</v-flex
                                              >
                                              <v-flex xs2 pb-1>
                                                <v-icon
                                                  size="125%"
                                                  color="green"
                                                  >mdi-arrow-up-thin-circle-outline</v-icon
                                                >
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs1 pr-3 pt-2 pb-2>
                                        <div class="vertical-divider"></div
                                      ></v-flex>
                                      <v-flex xs5 pt-4 align-self-center>
                                        <v-layout wrap>
                                          <v-flex xs12 text-left>
                                            <span
                                              class="mainfont"
                                              style="
                                                color: black;
                                                font-size: 15px;
                                              "
                                              >Minimum Price</span
                                            >
                                          </v-flex>

                                          <v-flex xs12 pb-4 text-center>
                                            <v-layout wrap justify-center>
                                              <v-flex
                                                text-left
                                                xs10
                                                class="mainfont"
                                                style="
                                                  color: black;
                                                  font-size: 14px;
                                                  font-weight: bold;
                                                "
                                                >Rs &nbsp;{{
                                                  item.minPrice
                                                }}</v-flex
                                              >
                                              <v-flex xs2 pb-1>
                                                <v-icon size="125%" color="red"
                                                  >mdi-arrow-down-thin-circle-outline</v-icon
                                                >
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>

                        <v-flex xs11 lg8>
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-card class="pt-8 pl-8 pr-8 pb-8">
                                <v-layout wrap>
                                  <v-flex xs12>
                                    <v-layout wrap justify-center>
                                      <v-flex xs11 lg4 pr-4>
                                        <v-menu
                                          ref="menu1"
                                          v-model="menu1"
                                          :close-on-content-click="false"
                                          max-width="290"
                                          style="box-shadow: none"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              class="rounded-lg dropfont"
                                              dense
                                              hide-details="auto"
                                              solo
                                              v-model="fromDate"
                                              v-bind="attrs"
                                              background-color="white"
                                              v-on="on"
                                              @click:clear="from = null"
                                              style="
                                                color: black;
                                                box-shadow: none;
                                              "
                                            >
                                              <template v-slot:append>
                                                <v-icon color="black"
                                                  >mdi-chevron-down</v-icon
                                                >
                                              </template>
                                              <template v-slot:label>
                                                <span
                                                  class="custom-label-text"
                                                  style="
                                                    color: black;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  From Date
                                                </span>
                                              </template>
                                            </v-text-field>
                                          </template>
                                          <v-date-picker
                                            style="box-shadow: none"
                                            v-model="fromDate"
                                            :min="minFromDate"
                                            color="#13736f"
                                            @change="menu1 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-flex>

                                      <v-flex lg4 xs11  pl-4>
                                        <v-menu
                                          ref="menu2"
                                          v-model="menu2"
                                          :close-on-content-click="false"
                                          max-width="290"
                                          elevation="0"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              elevation="0"
                                              label="To Date"
                                              dense
                                              background-color="white"
                                              color="#13736f"
                                              class="rounded-lg dropfont"
                                              solo
                                              v-model="toDate"
                                              v-bind="attrs"
                                              v-on="on"
                                              @click:clear="from = null"
                                              style="color: black"
                                            >
                                              <template v-slot:append>
                                                <v-icon color="black"
                                                  >mdi-chevron-down</v-icon
                                                >
                                              </template>
                                              <template v-slot:label>
                                                <span
                                                  class="custom-label-text"
                                                  style="
                                                    color: black;
                                                    font-size: 14px;
                                                  "
                                                >
                                                  To Date</span
                                                >
                                              </template>
                                            </v-text-field>
                                          </template>

                                          <v-date-picker
                                            v-model="toDate"
                                            color="#13736f"
                                            :max="maxToDate"
                                            @change="menu2 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 v-if="flag" class="mainfont">
                                    <div
                                      id="vuechart-example2"
                                      v-if="barlist.length > 0"
                                      class="mainfont"
                                    >
                                      <apexchart
                                        height="430"
                                        class="mainfont"
                                        type="bar"
                                        :options="chartOptions6"
                                        :series="series6"
                                      ></apexchart></div
                                  ></v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-tab-item value="tabs-4">
                  <v-layout
                    class="mainbg2"
                    pt-2
                    pr-2
                    pb-2
                    wrap
                    justify-start
                    pt-3
                  >
                    <v-flex xs12 text-center>
                      <v-layout wrap>
                        <v-flex xs11 lg4>
                          <v-layout wrap justify-center fill-height>
                            <v-flex xs10 align-self-center>
                              <v-card elevation="0" style="border-radius: 8px">
                                <v-layout wrap fill-height justify-center>
                                  <v-flex text-left xs10 pt-4 align-self-center>
                                    <span
                                      class="mainfont"
                                      style="
                                        color: black;
                                        font-size: 17px;
                                        font-weight: bold;
                                      "
                                      >Total Order Price</span
                                    >
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-layout wrap justify-end fill-height>
                                      <v-flex
                                        xs5
                                        pl-5
                                        align-self-center
                                        class="mainfont"
                                        style="
                                          color: black;
                                          font-size: 18px;
                                          font-weight: bold;
                                        "
                                        >Rs &nbsp;{{
                                          totalAuctionPrice2
                                        }}</v-flex
                                      >
                                      <v-flex
                                        xs1
                                        pb-1
                                        pr-16
                                        text-center
                                        align-self-center
                                        ><v-icon size="125%" color="green"
                                          >mdi-arrow-up-thin-circle-outline</v-icon
                                        ></v-flex
                                      >
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 pb-3 pr-5>
                                    <v-layout wrap justify-end fill-height>
                                      <v-flex
                                        xs6
                                        text-left
                                        align-self-center
                                        class="popfont"
                                        style="
                                          font-size: 15px;
                                          font-weight: bold;
                                        "
                                        >&nbsp;No of orders&nbsp;{{ orders }}
                                        </v-flex
                                      >
                                      <!-- <v-flex xs2 text-left align-self-center
                                        ><span class="mainfont"
                                          >&nbsp;{{ orders }}</span
                                        ></v-flex
                                      > -->
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout
                                wrap
                                justify-center
                                v-for="(item, i) in auctionbased"
                                :key="i"
                              >
                                <v-flex xs12 pa-2 text-center>
                                  <span
                                    class="mainfont"
                                    style="
                                      color: #13736f;
                                      font-size: 16px;
                                      font-weight: bold;
                                    "
                                    >{{ item.categoryName }}</span
                                  >
                                </v-flex>

                                <v-flex xs10 align-self-center>
                                  <v-card
                                    elevation="0"
                                    style="border-radius: 8px"
                                  >
                                    <v-layout wrap fill-height justify-center>
                                      <v-flex xs5 pt-4 align-self-center>
                                        <v-layout wrap justify-center>
                                          <v-flex xs8 text-left>
                                            <span
                                              class="mainfont"
                                              style="
                                                color: black;
                                                font-size: 15px;
                                              "
                                              >Total Price</span
                                            >
                                          </v-flex>

                                          <v-flex xs12 pb-4 text-center>
                                            <v-layout wrap justify-center>
                                              <v-flex
                                                xs8
                                                text-left
                                                class="mainfont"
                                                style="
                                                  color: black;
                                                  font-size: 14px;
                                                  font-weight: bold;
                                                "
                                              >
                                                Rs &nbsp;{{ item.totalPrice }}
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                      <v-flex xs1 pr-3 pt-2 pb-2>
                                        <div class="vertical-divider"></div
                                      ></v-flex>

                                      <v-flex xs5 pt-4 align-self-center>
                                        <v-layout wrap>
                                          <v-flex xs12 text-center>
                                            <span
                                              class="mainfont"
                                              style="
                                                color: black;
                                                font-size: 15px;
                                              "
                                              >Product Ordered</span
                                            >
                                          </v-flex>

                                          <v-flex xs12 pb-4 text-center>
                                            <v-layout wrap justify-center>
                                              <v-flex
                                                xs6
                                                class="mainfont"
                                                style="
                                                  color: black;
                                                  font-size: 14px;
                                                  font-weight: bold;
                                                "
                                                >{{
                                                  item.productsOrdered
                                                }}</v-flex
                                              >
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs11 lg8>
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-card class="pt-8 pl-8 pr-8 pb-8">
                                <v-layout wrap>
                                  <v-flex xs12>
                                    <v-layout wrap justify-center>
                                      <v-flex xs11 lg4 pr-4>
                                        <v-menu
                                          ref="menu3"
                                          v-model="menu3"
                                          :close-on-content-click="false"
                                          max-width="290"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              class="rounded-lg dropfont"
                                              dense
                                              hide-details="auto"
                                              solo
                                              v-model="fromDate2"
                                              v-bind="attrs"
                                              background-color="white"
                                              v-on="on"
                                              @click:clear="clearFromDate"
                                              style="color: black"
                                            >
                                              <template v-slot:append>
                                                <v-icon color="black"
                                                  >mdi-chevron-down</v-icon
                                                >
                                              </template>
                                              <template v-slot:label>
                                                <span
                                                  class="custom-label-text"
                                                  style="
                                                    color: black;
                                                    font-size: 14px;
                                                  "
                                                  >From Date</span
                                                >
                                              </template>
                                            </v-text-field>
                                          </template>
                                          <v-date-picker
                                            v-model="fromDate2"
                                            color="#13736f"
                                            @change="menu3 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-flex>

                                      <v-flex lg4 xs11 pl-4>
                                        <v-menu
                                          ref="menu4"
                                          v-model="menu4"
                                          :close-on-content-click="false"
                                          max-width="290"
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-text-field
                                              label="To Date"
                                              dense
                                              background-color="white"
                                              color="#13736f"
                                              class="rounded-lg dropfont"
                                              solo
                                              v-model="toDate2"
                                              v-bind="attrs"
                                              v-on="on"
                                              @click:clear="clearToDate"
                                              style="color: black"
                                            >
                                              <template v-slot:append>
                                                <v-icon color="black"
                                                  >mdi-chevron-down</v-icon
                                                >
                                              </template>
                                              <template v-slot:label>
                                                <span
                                                  class="custom-label-text"
                                                  style="
                                                    color: black;
                                                    font-size: 14px;
                                                  "
                                                  >To Date</span
                                                >
                                              </template>
                                            </v-text-field>
                                          </template>

                                          <v-date-picker
                                            v-model="toDate2"
                                            color="#13736f"
                                            @change="menu4 = false"
                                          ></v-date-picker>
                                        </v-menu>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                  <v-flex xs12 v-if="flag2" class="mainfont">
                                    <div
                                      id="vuechart-example3"
                                      v-if="barlist1.length > 0"
                                      class="mainfont"
                                    >
                                      <apexchart
                                        height="430"
                                        class="mainfont"
                                        type="area"
                                        :options="chartOptions"
                                        :series="series"
                                      ></apexchart></div
                                  ></v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
              </v-tabs-items>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import { mdiChevronDown } from "@mdi/js";

export default {
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      name: null,
      barlist: [],
      userProfileTabs: null,
      subtab: null,
      totalAuctionPrice2: null,
      totalAuctionPrice: null,
      orders: null,

      series: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        colors: ["#29807C", "#1CB1AB", "#00FFFF", "#0BDA51", "#50C878"],
        chart: {
          height: 430,
          background: "#ffff",
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          lineCap: "round",
          width: 2,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              fontFamily: "mainfont",
              color: "#333",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "mainfont",
              color: "#333",
            },
          },
        },
        grid: {
          showLines: true,
        },
        legend: {
          fontSize: "12px",
          fontFamily: "mainfont",
          textColor: "#555",
          position: "bottom",
          horizontalAlign: "center",
        },
        markers: {
          size: 3,
          colors: ["#29807C", "#1CB1AB", "#00FFFF", "#0BDA51", "#50C878"],
          strokeColors: "#fff",
          strokeWidth: 2,
        },
      },

      customHeight: 550,
      chartOptions6: {
        colors: ["#29807C", "#00FFFF"],
        chart: {
          type: "bar",
          height: 430,
          width: "100%",
          id: "vuechart-example2",
          background: "#ffff",
        },
        plotOptions: {
          bar: {
            columnWidth: "80%",
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: -1,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        fill: {
          colors: ["#29807C", "#00FFFF"],
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: "bold",
              fontFamily: "mainfont",
              color: "#333",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "mainfont",
              color: "#333",
            },
          },
        },
        grid: {
          showLines: true,
        },
        legend: {
          fontSize: "12px",
          fontFamily: "mainfont",
          textColor: "#555",
          position: "top",
          horizontalAlign: "center",
        },
        series: [
          {
            name: "Series 1",
            data: [],
          },
          {
            name: "Series 2",
            data: [],
          },
        ],
      },

      currentAuctions: [],
      upcomingAuctions: [],
      previousAuctions: [],

      appLoading: false,
      page: 1,
      pages: 0,
      dialog1: false,

      preview: null,
      tab: null,
      barlist1: [],
      items: ["Dashboard", "Auctions"],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      formData: new FormData(),
      id: this.$route.query.id,
      productname: null,
      productarray: [],
      list: {},
      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      limit: 10,
      fromDate: null,
      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      showSnackBar: false,
      curid: [],
      dialog2: false,
      series6: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
      series1: [
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
        {
          name: "",
          data: [],
        },
      ],
    };
  },
  watch: {
    fromDate() {
      this.auctionBasedlist();
    },
    toDate() {
      this.auctionBasedlist();
    },
    fromDate2(newFromDate) {
      if (!newFromDate) {
        this.toDate2 = null;
      } else {
        this.setToDate2To6DaysAhead(newFromDate);
      }
      this.orderBasedlist();
    },
    toDate2(newToDate) {
      if (!newToDate) {
        this.fromDate2 = null;
      } else {
        this.setFromDate2To6DaysBefore(newToDate);
      }
      this.orderBasedlist();
    },
  },
  mounted() {
    this.fun2();

    this.fun1();
    this.auctionBasedlist();
    this.orderBasedlist();
  },
  computed: {
    chevronDownIcon() {
      return mdiChevronDown;
    },
    maxToDate() {
      const maxDate = new Date(this.fromDate);
      maxDate.setFullYear(maxDate.getFullYear() + 1);
      return maxDate.toISOString().split("T")[0];
    },
    minFromDate() {
      const minDate = new Date(this.toDate);
      minDate.setFullYear(minDate.getFullYear() - 1);
      return minDate.toISOString().split("T")[0];
    },
  },

  methods: {
    fun2() {
      const today = new Date();
      this.toDate = today.toISOString().split("T")[0];

      const fromDate = new Date(today);
      fromDate.setMonth(fromDate.getMonth() - 1);
      this.fromDate = fromDate.toISOString().split("T")[0];
    },
    fun1() {
      const today = new Date();
      this.toDate2 = today.toISOString().split("T")[0];

      const fromDate = new Date(today);
      fromDate.setDate(fromDate.getDate() - 6);
      this.fromDate2 = fromDate.toISOString().split("T")[0];
    },
    clearFromDate() {
      this.fromDate2 = null;
      this.setToDate2To6DaysAhead(this.fromDate2);
      this.orderBasedlist();
    },
    clearToDate() {
      this.toDate2 = null;
      this.setToDate2To6DaysAhead(this.toDate2);
      this.orderBasedlist();
    },
    setToDate2To6DaysAhead(fromDate) {
      const toDate = new Date(fromDate);
      toDate.setDate(toDate.getDate() + 6);
      this.toDate2 = toDate.toISOString().split("T")[0];
    },
    setFromDate2To6DaysBefore(toDate) {
      const fromDate = new Date(toDate);
      fromDate.setDate(fromDate.getDate() - 6);
      this.fromDate2 = fromDate.toISOString().split("T")[0];
    },
    auctionBasedlist() {
      this.appLoading = true;
      this.flag = false;
      axios({
        method: "GET",
        url: "/admin/auction/analytics",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.barlist = response.data.graph;
            this.totalAuctionPrice = response.data.totalAuctionPrice;
            this.chartOptions6.xaxis.categories = [];
            const maxPriceData = [];
            const minPriceData = [];

            for (let i = 0; i < this.barlist.length; i++) {
              this.chartOptions6.xaxis.categories.push(
                this.barlist[i].categoryName
              );
              maxPriceData.push(this.barlist[i].maxPrice);
              minPriceData.push(this.barlist[i].minPrice);
            }

            this.series6 = [
              { name: "Max Price", data: maxPriceData },
              { name: "Min Price", data: minPriceData },
            ];

            this.flag = true;
          }
          this.appLoading = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    orderBasedlist() {
      this.appLoading = true;
      this.flag2 = false;
      axios({
        method: "GET",
        url: "/admin/order/analytics",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate2,
          to: this.toDate2,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.orders = response.data.orders;
            this.auctionbased = response.data.data;
            this.barlist1 = response.data.graph;
            this.totalAuctionPrice2 = response.data.totalOrderPrice;
            this.chartOptions.xaxis.categories = [];
            const categoriesData = {};
            for (let i = 0; i < this.barlist1.length; i++) {
              this.chartOptions.xaxis.categories.push(this.barlist1[i].day);
              const categoryWisePrice = this.barlist1[i].categoryWisePrice;
              for (let j = 0; j < categoryWisePrice.length; j++) {
                const categoryName = categoryWisePrice[j].categoryName;
                const orderTotal = categoryWisePrice[j].orderTotal;
                if (!categoriesData[categoryName]) {
                  categoriesData[categoryName] = [];
                }
                categoriesData[categoryName].push(orderTotal);
              }
            }
            this.series = Object.entries(categoriesData).map(
              ([name, data]) => ({
                name,
                data,
              })
            );
            this.flag2 = true;
          }
          this.appLoading = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

  <style >
.cus3 {
  height: 50px;
  background-color: white;
}
.vertical-divider {
  border-right: 1px solid black;
  height: 70px;
}
.v-date-picker .v-picker__body {
  box-shadow: none;
}
#chart-container {
  background: linear-gradient(180deg, #29807c 0%, rgba(5, 112, 107, 0.16) 100%);
}
</style>